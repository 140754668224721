@import "./var.scss";

.Range {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: fit-content;
    .multi-range {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        height: 36px;
        .thumb {
            position: absolute;
            width: 100%;
            height: 10px;
            border-radius: 10px;
            z-index: 0;
            @-moz-document url-prefix() {
                z-index: -1;
            }
        }
        input[type="range"] {
            padding: 0;
            position: absolute;
            box-sizing: border-box;
            appearance: none;
            width: 100%;
            margin: 0;
            padding: 0;
            overflow: hidden;
            border: 0;
            border-radius: 1px;
            outline: none;
            background-size: 100% 2px;
            pointer-events: none;
            aspect-ratio: $naranja;
            background: transparent;

            &:active,
            &:focus {
                outline: none;
            }
            &::-moz-range-thumb {
                height: 20px;
                width: 20px;
                border-radius: 28px;
                background-color: $naranja;
                -webkit-appearance: none;
                z-index: 10;
                position: relative;
                margin: 5px 0;
                cursor: pointer;
                appearance: none;
                pointer-events: all;
                box-shadow:
                    0 3px 6px rgba(0, 0, 0, 0.16),
                    0 3px 6px rgba(0, 0, 0, 0.23);
            }
            &::-webkit-slider-thumb {
                height: 20px;
                width: 20px;
                border-radius: 28px;
                background-color: $naranja;
                -webkit-appearance: none;
                z-index: 10;
                position: relative;
                margin: 5px 0;
                cursor: pointer;
                appearance: none;
                pointer-events: all;
                box-shadow:
                    0 3px 6px rgba(0, 0, 0, 0.16),
                    0 3px 6px rgba(0, 0, 0, 0.23);
            }
            &:nth-child(2) {
                background: none;
            }
        }
    }
    .rangeText {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .max {
            justify-content: flex-end;
        }
        .min,
        .max {
            display: flex;
            color: $naranja;
            align-items: center;
            flex-wrap: wrap;
            font-family: Work Sans;
            row-gap: 0px;
            column-gap: 8px;

            p {
                margin: 0;
                font-size: 16px;
            }
            b {
                font-size: 24px;
            }
        }
    }

    @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        justify-content: space-between;
        height: fit-content;
        gap: 2px;
        .rangeText {
            .max {
                column-gap: 4px;
                p {
                    text-align: flex-end;
                }
            }
            .max,
            .min {
                p {
                    font-size: 14px;
                }
                b {
                    font-size: 16px;
                }
            }
        }
    }
}
