@import "./mixin.scss";
@import "./var.scss";

.form {
	.border_box {
		width: 59.7333vw;
		padding: 0.5vw 1.5vw;
		border-radius: 10px;
		-moz-border-radius: 10px;
		-webkit-border-radius: 10px;
		border: 1px solid $gris;
		appearance: none;
		-webkit-appearance: none;
	}

	label,
	textarea,
	input[type="text"],
	input[type="email"],
	input[type="tel"],
	input[type="number"],
	input[type="password"],
	select {
		width: 100%;
		font-size: 0.9vw;
		display: inline-block;
		vertical-align: top;
		color: $gris;
		border: none;
		text-transform: uppercase;
		font-weight: 400;
		//line-height: 18px;
	}

	.inputnone {
		text-transform: none !important;
	}

	input[type="date"],
	input[type="time"] {
		appearance: none;
		-webkit-appearance: none;
	}

	input,
	textarea {
		&:focus {
			outline: none !important;
		}
	}

	input[type="text"]:focus,
	input[type="password"]:focus,
	input[type="email"]:focus,
	input[type="search"]:focus,
	input[type="tel"]:focus,
	select:focus,
	textarea:focus,
	button:focus,
	*.btn:active,
	.btn:focus,
	.btn:active:focus,
	.btn.active:focus {
		outline: none !important;
	}

	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		-webkit-box-shadow: 0 0 0 30px white inset !important;
	}

	select {
		// color: white;
		border: none;
		width: 100%;

		&::-ms-expand {
			color: #ff0000;
			/* Color de la flecha en Internet Explorer */
		}

		&::after {
			color: #ff0000;
			/* Color de la flecha en otros navegadores */
		}

		option {
			color: $gris;
			background-color: white;

			&:hover {
				background-color: #000;
				color: white;
			}
		}
	}

	input[type="text"],
	input[type="password"],
	input[type="email"],
	input[type="tel"],
	input[type="number"] {
		&::placeholder {
			color: $gris;
			opacity: 1;
		}

		&:-ms-input-placeholder {
			color: $gris;
		}

		&::-ms-input-placeholder {
			color: $gris;
		}
	}

	label {
		vertical-align: baseline;
		margin-bottom: 0rem;
	}

	button {
		&.btn_form {
			background-color: $naranja !important;
			text-align: center;
			//padding: 1vw 2vw;
			font-size: 1.2vw;
			font-weight: 500;
			width: 16vw;
		}

		&.btn_formactua {
			background-color: $naranja !important;
			text-align: center;
			padding: 1vw 2vw;
			font-size: 1.2vw;
			font-weight: 500;
		}
	}

	.ht_margin {
		height: 40vh;
	}

	.modal-body,
	.modal-content {
		border: none;
	}
}



.formgrupos {
	background-color: #FFF;
	width: 100%;
	position: relative;
	display: inline-block;
	border: 1px solid $gris;
	padding: 0.5vw 1.5vw;
	line-height: 100%;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	transition: border-color 0.3s;
}


.formgrupos_focus {
	border-color: $naranja;
}

.formgrupos_error {
	border-color: red;
}

.formgrupos_acierto {
	border-color: rgb(0, 255, 55);
}

.icon {
	color: red;
}

.iconAcierto {
	color: rgb(0, 255, 55);
}

.formgrupos_inactivo {
	opacity: 0.3;
	pointer-events: none;
	background-color: $gris_2;
}

.labels {
	margin-top: 0;
	margin-bottom: 0.2rem;
}

.bigLabels {
	font-size: 13pt;
	font-weight: bold;
	margin-bottom: 8pt;
	text-transform: capitalize;
	line-height: 1.1;
}

.cols {
	//height: 90pt;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
}

.inputs {
	background-color: transparent;
}

.input_check {
	border-radius: 50%;
	border: 1px solid $naranja ;
}

.input_check_circular {
	font-size: 1vw;
}

.labels_check {
	font-size: 1vw;
	vertical-align: center;
	display: inline-block;
}

.btn_form,
.btn_formactua {
	width: 100%;
	@include toAnim(0.3s);

	&:hover {
		background-color: $ladrillo;
	}
}

.searcher {
	width: 18vw;
	border: 1px solid $gris;
	display: flex;
	align-items: center;
	padding: 0.5vw 0.8vw;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
}

.inputFechas {

	input,
	label {
		cursor: pointer;
	}
}

.imputModalFecha {
	border-bottom: 1px solid $gris_5;
}

.inputsfecha {
	border: 0;
	width: 100%;
}

.btnSearch {
	color: $gris;
	background-color: transparent;
	border: 0;
	font-size: 1.2vw;
	line-height: 100%;
	display: inline-block;
	padding-right: 0.8vw;
}

.input {
	border: 0;
	font-size: 0.8vw;
	line-height: 100%;
	display: inline-block;

	&:focus {
		outline: none;
	}
}

.cardformularioCodigo {
	display: flex;
	align-items: center;
	justify-content: center;
}

.formularioCodigo {
	width: 25vw;
}

.formulario70 {
	width: 80%;
}

.customFileInput {
	display: none; // Oculta el input original
}

.customFileLabel {
	padding: .5vw;
	cursor: pointer;
	margin: 0 auto;
}

.customFileInput:checked+.customFileLabel {
	background-color: transparent;
}

.filterErea {
	box-shadow: 0px 0px 10px 0px #00000033;
	background-color: white;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	padding: 1vw;
	display: inline-flex;

	.imgFilter {
		position: relative;
		margin-right: 1vw;
		max-height: 1.5vw;
	}
}

.selecFilter {
	border: 0;
}

.form_iframe {
	height: 100vh;


}

//big desktop
@media (min-width: 1920px) {
	.filterErea {

		padding: 1rem;

		.imgFilter {
			margin-right: 1rem;
			max-height: 1.5rem;
		}
	}
	.form {
		label,
		textarea,
		input[type="text"],
		input[type="email"],
		input[type="tel"],
		input[type="number"],
		input[type="password"],
		select {
			font-size: 0.8rem;
			//line-height: 18px;
		}
	}

	.formgrupos {
		padding: 1rem 1.5rem;
	}
}


// big tablet LG
@media (max-width: 1200px) {
	.searcher {
		width: auto;
	}
}

// all tablet 
@media (min-width: 768px) and (max-width: 1200px) {

	.input {
		font-size: 0.7rem;
	}
}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {

	.filterErea {

		padding: 1rem;

		.imgFilter {

			margin-right: 1rem;
			max-height: 1.5rem;
		}
	}

	.formularioCodigo {
		width: 24rem;
	}

	.input_check_circular {
		font-size: 1rem;
	}

	.form {
		button {
			&.btn_form {
				font-size: 1.2rem;
				width: 12rem;
				padding: 0.5rem;
			}

			&.btn_formactua {
				font-size: 1.2rem;
				width: 12rem;
				padding: 0.5rem;
				width: 100%;
			}
		}

		.border_box {
			width: 100%;
			padding: 0.5vw 1.5vw;
		}

		label,
		textarea,
		input[type="text"],
		input[type="email"],
		input[type="tel"],
		input[type="number"],
		input[type="password"],
		select {
			font-size: 0.9rem;
			//line-height: 18px;
		}

		.ht_margin {
			height: 10vh;
		}
	}

	.searcher {
		padding: 0.5rem 0.8rem;
	}
}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
	.filterErea {

		padding: 1rem;

		.imgFilter {

			margin-right: 1rem;
			max-height: 1.5rem;
		}
	}

	.formularioCodigo {
		width: 24rem;
	}



	.form {
		button {
			&.btn_form {
				font-size: 1.2rem;
				width: 12rem;
				padding: 0.5rem;
			}

			&.btn_formactua {
				font-size: 1.2rem;
				width: 12rem;
				padding: 0.5rem;
				width: 100%;
			}
		}

		.border_box {
			width: 100%;
			padding: 0.5vw 1.5vw;
		}

		label,
		textarea,
		input[type="text"],
		input[type="email"],
		input[type="tel"],
		input[type="number"],
		input[type="password"],
		select {
			font-size: 0.9rem;
			//line-height: 18px;
		}

		.ht_margin {
			height: 8vh;
		}
	}

	.input_check_circular {
		font-size: 1rem;
	}
}

@media only screen and (min-width: 577px) and (max-width: 767px) {

	.filterErea {

		padding: 1rem;

		.imgFilter {

			margin-right: 1rem;
			max-height: 1.5rem;
		}
	}

	.form {
		button {
			&.btn_form {
				font-size: 1.2rem;
				width: 12rem;
				padding: 0.5rem;
			}

			&.btn_formactua {
				font-size: 1.2rem;
				width: 12rem;
				padding: 0.5rem;
				width: 100%;
			}
		}

		.border_box {
			width: 100%;
			padding: 0.5vw 1.5vw;
		}

		label,
		textarea,
		input[type="text"],
		input[type="email"],
		input[type="tel"],
		input[type="number"],
		input[type="password"],
		select {
			font-size: 0.8rem;
			//line-height: 18px;
		}

		.ht_margin {
			height: 10vh;
		}
	}

	.input_check_circular {
		font-size: 1rem;
	}
}

// tablet MD
@media (max-width: 768px) {


	.formularioCodigo {
		width: 24rem;
	}

	.input {
		font-size: 0.7rem;
	}

	.searcher {
		padding: 0.5rem 0.8rem;
	}
}

//only phone
@media only screen and (max-width: 576px) {

	.filterErea {

		padding: 1rem;

		.imgFilter {

			margin-right: 1rem;
			max-height: 1.5rem;
		}
	}

	.formularioCodigo {
		width: 95%;
	}

	.searcher {
		padding: 0.5rem 0.8rem;
	}

	.btnSearch {
		font-size: 1rem;
		padding-right: 0.8rem;
	}

	.input {
		font-size: 1rem;
	}

	.labels_check {
		font-size: 0.8rem;
	}

	.input_check_circular {
		font-size: 1rem;
	}

	.form {
		button {
			&.btn_form {
				font-size: 1.2rem;
				width: 10rem;
			}

			&.btn_formactua {
				font-size: 1.2rem;
				width: 10rem;
				width: 100%;
			}

		}

		.border_box {
			width: 28rem;
			padding: 0.5vw 1.5vw;
		}

		label,
		textarea,
		input[type="text"],
		input[type="email"],
		input[type="tel"],
		input[type="number"],
		input[type="password"],
		select {
			font-size: 0.8rem;
			//line-height: 18px;
		}

		.ht_margin {
			height: 10vh;
		}
	}

	.formgrupos {
		padding: 0.5rem 1rem;
	}
}