@import './mixin.scss';

.banner {
    position: relative;
    //min-height: 60vh;
    //background-color: white;
}

.sombra::before {
    position: absolute;
    content: '';
    left: 0 !important;
    top: 0%;
    bottom: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) -3.09%, rgba(37, 35, 36, 0.7) 85.37%, rgba(89, 84, 86, 0.7) 144.56%);
    width: 100% !important;
}

.wp {
    width: 100%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: 1;

    h1 {
        /* font-size: 150px; */
        /* font-size: 7.8vw; */
        font-size: 4vw;
        line-height: 90%;
        text-shadow: 2px 3px 3px rgba(0, 0, 0, 0.35);
    }

    h2,
    .h2 {
        font-size: 3vw;
        line-height: 120%;
    }

    h3 {
        font-size: 1.6vw;
    }
}

.fullLink {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.video {
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    top: 0;
    left: 0;
    @include toAnim(0.3s);
}

.btnPlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    border: 0;
    background-color: transparent;
    color: white;
    font-size: 3vw;
}

/* .container {
  max-width: 95%;
} */

.list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.itemList {
    width: 6.5vw;
    text-align: center;

    img {
        height: 3vw;
        margin: 0 auto;
    }

    span {
        width: 100%;
        font-size: 0.8vw;
        display: inline-block;
    }
}

// big desktop
@media only screen and (min-width: 1921px) {
    .wp {
        h1 {
            /* font-size: 9rem; */
            font-size: 2.5rem;
        }

        h2,
        .h2 {
            /* font-size: 7rem; */
            font-size: 1.8rem;
        }

        h3 {
            /* font-size: 2.5rem; */
            font-size: 1.3rem;
        }
    }
}

// only desktop XL
@media only screen and (min-width: 1200px) and (max-width: 1920px) {}

//Laptop
@media only screen and (min-width: 1200px) and (max-width: 1600px) {}

//only tablet
@media only screen and (min-width: 768px) and (max-width: 1200px) {
    .itemList {
        width: 7rem;

        img {
            height: 1.5rem !important;
        }

        span {
            width: 100%;
            font-size: 0.8rem;
        }
    }

    .banner {
        min-height: 25vh;
        display: flex;

        picture {
            display: block;

            img {
                height: 100%;
                object-fit: cover;
            }
        }

        .img_background {
            display: block;
            height: 100%;
            object-fit: cover;
        }
    }
}

// big tablet LG
@media (max-width: 1200px) {
    .wp {
        h1 {
            font-size: 3rem;
        }

        h2,
        .h2 {
            font-size: 2.5rem;
        }
    }
}

// tablet MD
@media (max-width: 768px) {
    .wp {
        h1 {
            font-size: 2.5rem;
        }

        h2,
        .h2 {
            font-size: 2rem;
        }

        h3 {
            font-size: 1.5rem;
        }
    }

    .itemList {
        width: 30%;

        img {
            height: 2.5rem !important;
        }

        span {
            width: 100%;
            font-size: 1rem;
        }
    }

    .banner {
        min-height: 25vh;
        display: flex;

        picture {
            display: block;

            img {
                height: 100%;
                object-fit: cover;
            }
        }

        .img_background {
            display: block;
            height: 100%;
            object-fit: cover;
        }
    }
}

@media (max-width: 768px) and (orientation: landscape) {
    .banner {
        min-height: 60vh;
        display: flex;

        picture {
            display: block;

            img {
                height: 100%;
                object-fit: cover;
            }
        }

        .img_background {
            display: block;
            height: 100%;
            object-fit: cover;
        }
    }
}

// only mobile

@media (max-width: 576px) {
    .banner {
        min-height: 50vh;
        display: flex;

        picture {
            display: block;

            img {
                height: 100%;
                object-fit: cover;
            }
        }

        .img_background {
            display: block;
            height: 100%;
            object-fit: cover;
        }
    }

    .wp {
        h1 {
            font-size: 2.4rem;
        }

        h2,
        .h2 {
            font-size: 2.1rem;
        }
    }
}

// only mobile iphone 13 

@media (max-width: 300px) {
    .wp {
        h1 {
            font-size: 2rem;
        }

        h2,
        .h2 {
            font-size: 1.5rem;
        }
    }
}

.img_background {
    display: block;
    height: 100%;
    object-fit: cover;
}