@import "./var.scss";
@import "./mixin.scss";

.btnBuscador {
    width: 100%;
    padding: 0.5rem 1rem;
    background-color: $naranja;
    border: 1px solid $naranja;
    color: white;
    position: relative;
    font-size: 14px;
    font-weight: 600;
    border-radius: 10px;
    text-decoration: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    display: inline-block;
    white-space: nowrap;
    @include toAnim(0.3s);
    border: 0;
    position: relative;

    .tooltip {
        display: none;
    }

    .textHiddentTime {
        position: relative;
        top: -1rem;
    }

    @media screen and (min-width: 760px) {
        padding-bottom: 0.4rem;
        padding-top: 0.4rem;
        width: 200px;
        font-size: 16px;

        .tooltip {
            display: block;
            position: absolute;
            color: #f47921;
            background-color: white;
            top: -8rem;
            left: -100%;
            padding: 24px 46px;
            border-radius: 14px;
            font-weight: 700;
            font-size: 24px;
            //animation: buttonDisappear 7s 3s ease-in;
            // animation-fill-mode: forwards;

            &::after {
                content: "";
                display: block;
                position: absolute;
                bottom: -1.4rem;
                width: 2rem;
                height: 2rem;
                background-color: white;
                right: 0;
                //clip-path: polygon(-1% 0, 100% 100%, 100% 0);
            }
        }
    }
}

@keyframes buttonDisappear {
    0% {
        opacity: 1;
        visibility: visible;
    }

    95% {
        opacity: 0;
        visibility: visible;
    }

    100% {
        opacity: 0;
        display: none;
    }
}



.btn {
    width: 100%;
    padding: 0.5rem 1rem;
    background-color: $naranja;
    border: 1px solid $naranja;
    color: white;
    position: relative;
    font-size: 14px;
    font-weight: 600;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    text-decoration: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    display: inline-block;
    white-space: nowrap;
    @include toAnim(0.3s);
    border: 0;
    position: relative;

    &:hover {
        text-decoration: none;
    }



}

.btn_brillo {
    border: 1px solid;
    position: relative;

    .tooltip {
        display: block;
        position: absolute;
        color: #f47921;
        background-color: white;
        top: -8rem;
        left: -100%;
        padding: 24px 46px;
        border-radius: 14px;
        font-weight: 700;
        font-size: 24px;
        //animation: buttonDisappear 7s 3s ease-in;
        animation-fill-mode: forwards;

        &::after {
            content: "";
            display: block;
            position: absolute;
            bottom: -1.4rem;
            width: 2rem;
            height: 2rem;
            background-color: white;
            right: 0;
            clip-path: polygon(-1% 0, 100% 100%, 100% 0);
        }
    }

    .brillo {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        overflow: hidden;

        &:after {
            background: #fff;
            content: "";
            height: 155px;
            left: -75px;
            opacity: 0.7;
            position: absolute;
            top: -50px;
            transform: rotate(35deg);
            width: 50px;
        }
    }

    .highlighted {
        &:after {
            animation: move 5s linear infinite;
        }
    }

}

/* .btn_brillo:hover {
    .brillo {
        &:after {
            left: 120%;
            transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
        }
    }

} */

@keyframes move {
    0% {
        left: -250px;
        /* animation-duration: 5s; */
        visibility: visible;
    }

    50% {
        /* 100% width of the button minus the width of the moving element */
    }

    55% {
        left: 150%;
    }

    99% {
        left: 120%;
        opacity: 0;

    }

    100% {
        left: -200px;
    }
}

@media screen and (min-width: 760px) {

    .btn {
        padding-bottom: 0.4rem;
        padding-top: 0.4rem;
        width: 200px;
        font-size: 16px;

        .tooltip {
            display: block;
            position: absolute;
            color: #f47921;
            background-color: white;
            top: -6.6rem;
            left: 15%;
            padding: 18px 25px;
            border-radius: 14px;
            font-weight: 700;
            font-size: 22px;
            //animation: buttonDisappear 7s 3s ease-in;
            animation-fill-mode: forwards;

            &::after {
                content: "";
                display: block;
                position: absolute;
                bottom: -1.2rem;
                width: 2rem;
                height: 2rem;
                background-color: #fff;
                left: 0;
                clip-path: polygon(100% 0, 0 0, 0 100%);
            }
        }
    }

}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
    .btn {
        .tooltip {
            top: -6.5rem;
            left: -10%;
            top: -6.5rem;
            left: -10%;
            font-size: 16px;
            animation: buttonDisappear 7s 3s ease-in;
            animation-fill-mode: forwards;

            &::after {
                clip-path: polygon(-1% 0, 100% 100%, 100% 0) ;
            }
        }
    }
}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
    .btn {
        .tooltip {
            top: -6.5rem;
            left: -10%;
            font-size: 16px;
            //animation: buttonDisappear 7s 3s ease-in;
            // animation-fill-mode: forwards;

            &::after {
                clip-path: polygon(-1% 0, 100% 100%, 100% 0) ;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .btn {
        .tooltip {
            display: none !important;
            visibility: hidden !important;
        }
    }
    
}